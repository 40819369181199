import React, { Suspense } from "react";
import { DashboardLayout } from "@/components";
import { config } from "@/utils/config";
import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from "react-router-dom";
import LoadingScreen from "@/components/common/loading-screen";
import { adminApproverCreatorRoles, EUserRole } from "@/modules/authentication/authentication.type";
import Permission from "@/modules/authentication/components/Permission/Permission";
import { RequireAnonymous } from "@/modules/authentication/components/RequireAnonymous";
import { RequireAuth } from "@/modules/authentication/components/RequireAuth";
import LimitAccess from "@/modules/limit-access";
import {
  AuthenticationFeature,
  Discovery,
  DraftContainer,
  EditScreen,
  Favourites,
  HelpLayout,
  MyWorkbook,
  Recent,
  Request,
  SettingLayout,
  Share,
  Statistics,
  ViewScreen,
  WorkbookDetails,
  Workspace,
} from "./lazy-routes";
import { APP_ROUTES } from "./routes";

const configAppRoutes: RouteObject[] = [
  {
    path: APP_ROUTES.LIMIT_ACCESS.path,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <LimitAccess />
      </Suspense>
    ),
  },
  {
    path: APP_ROUTES.LOGIN.path,
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <RequireAnonymous>
          <AuthenticationFeature />
        </RequireAnonymous>
      </Suspense>
    ),
    index: true,
  },
  ...(config().IS_DEV_ENV
    ? [
        {
          path: APP_ROUTES.DRAFT.path,
          element: (
            <Suspense fallback={<LoadingScreen />}>
              <DraftContainer />
            </Suspense>
          ),
        },
      ]
    : []),
  {
    path: APP_ROUTES.EDIT.path,
    element: (
      <RequireAuth>
        <Permission
          roles={adminApproverCreatorRoles}
          noAccess={<Navigate to={APP_ROUTES.DISCOVERY.to} />}
        >
          <Suspense fallback={<LoadingScreen />}>
            <EditScreen />
          </Suspense>
        </Permission>
      </RequireAuth>
    ),
    index: true,
  },
  {
    path: APP_ROUTES.VIEW.path,
    element: (
      <RequireAuth>
        <Suspense fallback={<LoadingScreen />}>
          <ViewScreen />
        </Suspense>
      </RequireAuth>
    ),
    index: true,
  },
  {
    element: (
      <RequireAuth>
        <DashboardLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: APP_ROUTES.DISCOVERY.path,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <Discovery />
          </Suspense>
        ),
      },
      {
        path: APP_ROUTES.FAVOURITES.path,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <Favourites />
          </Suspense>
        ),
      },
      {
        path: APP_ROUTES.RECENT.path,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <Recent />
          </Suspense>
        ),
      },
      {
        path: APP_ROUTES.MY_WORKBOOK.path,
        element: (
          <Permission
            roles={[EUserRole.ADMIN, EUserRole.CREATOR]}
            noAccess={<Navigate to={APP_ROUTES.DISCOVERY.to} />}
          >
            <Suspense fallback={<LoadingScreen />}>
              <MyWorkbook />
            </Suspense>
          </Permission>
        ),
      },
      {
        path: APP_ROUTES.SHARE_WITH_ME.path,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <Share />
          </Suspense>
        ),
      },
      {
        path: APP_ROUTES.REQUEST.path,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <Request />
          </Suspense>
        ),
      },
      {
        path: APP_ROUTES.WORKSPACE.path,
        element: (
          <Permission
            roles={[EUserRole.ADMIN, EUserRole.CREATOR, EUserRole.APPROVER]}
            noAccess={<Navigate to={APP_ROUTES.DISCOVERY.to} />}
          >
            <Suspense fallback={<LoadingScreen />}>
              <Workspace />
            </Suspense>
          </Permission>
        ),
      },
      {
        path: APP_ROUTES.STATISTICS.path,
        element: (
          <Permission
            roles={[EUserRole.ADMIN, EUserRole.CREATOR, EUserRole.APPROVER]}
            noAccess={<Navigate to={APP_ROUTES.DISCOVERY.to} />}
          >
            <Suspense fallback={<LoadingScreen />}>
              <Statistics />
            </Suspense>
          </Permission>
        ),
      },
      {
        path: APP_ROUTES.WORKBOOK_DETAILS.path,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <WorkbookDetails />
          </Suspense>
        ),
      },
      {
        path: APP_ROUTES.SETTINGS.path,
        element: (
          <Permission
            roles={[EUserRole.ADMIN]}
            noAccess={<Navigate to={APP_ROUTES.DISCOVERY.to} />}
          >
            <Suspense fallback={<LoadingScreen />}>
              <SettingLayout />
            </Suspense>
          </Permission>
        ),
      },
      {
        path: APP_ROUTES.HELP.path,
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <HelpLayout />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to={APP_ROUTES.LOGIN.to} />,
  },
];

const router = createBrowserRouter(configAppRoutes);

export const Routes: React.FC = () => {
  return <RouterProvider router={router} />;
};
