export const APP_ROUTES = {
  LIMIT_ACCESS: {
    path: "concurrent-users-limit/*",
    to: "/concurrent-users-limit",
  },
  LOGIN: {
    path: "login/*",
    to: "/login",
  },
  DASHBOARD: {
    path: "dashboard/*",
    to: "/dashboard",
  },
  ACCOUNTS: {
    USERS: {
      path: "accounts/users/*",
      to: "/accounts/users",
    },
    ANALYST: {
      path: "accounts/analyst/*",
      to: "/accounts/analyst",
    },
    ADMINISTRATOR: {
      path: "accounts/administrator/*",
      to: "/accounts/administrator",
    },
  },
  EDIT: {
    path: "edit/*",
    to: "/edit",
  },
  VIEW: {
    path: "view/*",
    to: "/view",
  },
  DISCOVERY: {
    path: "discovery/*",
    to: "/discovery",
  },
  // CATEGORY: {
  //   path: "category/*",
  //   to: "/category",
  // },
  FAVOURITES: {
    path: "favourites/*",
    to: "/favourites",
  },
  RECENT: {
    path: "recent/*",
    to: "/recent",
  },
  MY_WORKBOOK: {
    path: "my-workbooks/*",
    to: "/my-workbooks",
  },
  SHARE_WITH_ME: {
    path: "shared-with-me/*",
    to: "/shared-with-me",
  },
  REQUEST: {
    path: "request/*",
    to: "/request",
  },
  WORKSPACE: {
    path: "workspace/*",
    to: "/workspace",
  },
  STATISTICS: {
    path: "statistics/*",
    to: "/statistics",
  },
  DRAFT: {
    path: "draft/*",
    to: "/draft",
  },
  WORKBOOK_DETAILS: {
    path: "my-workbooks/:id",
    to: "/my-workbooks/:id",
  },
  SETTINGS: {
    path: "settings/*",
    to: "/settings",
  },
  HELP: {
    path: "help/*",
    to: "/help",
  },
};
