import APIInstance from "@/utils/apiConfig";
import axios, { AxiosResponse } from "axios";
import { LoginResponse, TokenRequest } from "@/models/authentication";

const authApi = {
  login({ tokreq }: TokenRequest): Promise<AxiosResponse<LoginResponse>> {
    return APIInstance.post("/getusertoken", { tokreq });
  },
  getProfile() {
    return axios.get("/me");
  },
};

export default authApi;
